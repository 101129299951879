<template>
    <v-layout row justify-center>
        <v-snackbar v-model="showAlert" :timeout="4000" :top="true" color="error">
            {{ errorMessage }}
        </v-snackbar>

        <v-dialog v-model="showSignUp" persistent max-width="600px">
            <!--<btn slot="activator" color="primary" dark small>Open Dialog</btn>-->
            <v-card>
                <v-card-title> <span class="headline">Sign Up</span> </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md4>
                                <text-field
                                    label="First name*"
                                    v-model="firstName"
                                    required
                                ></text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                                <text-field
                                    label="Last name*"
                                    v-model="lastName"
                                    required
                                ></text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                                <text-field
                                    label="User Code*"
                                    v-model="userCode"
                                    required
                                ></text-field>
                            </v-flex>
                            <v-flex xs12>
                                <text-field
                                    label="Email*"
                                    persistent-hint
                                    v-model="email"
                                    required
                                ></text-field>
                            </v-flex>
                            <v-flex xs12>
                                <text-field
                                    label="Password*"
                                    type="password"
                                    v-model="password"
                                    required
                                ></text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md4>
                                <text-field
                                    label="Organisation*"
                                    v-model="org"
                                    required
                                ></text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                                <text-field
                                    type="password"
                                    label="Auth Token*"
                                    v-model="authToken"
                                    required
                                ></text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <btn color="blue darken-1" text @click="gotoLogin" small>go to Login</btn>
                    <v-spacer></v-spacer>
                    <btn color="blue darken-1" text @click="doSignup" small
                        >Complete Signup</btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
// const api = require('@/api');
/* eslint-disable */

import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showSignUp: true,
            firstName: '',
            lastName: '',
            userCode: '',
            authToken: '',
            email: '',
            org: '',
            password: '',
            showAlert: false,
            errorMessage: '',
        };
    },
    methods: {
        ...mapActions(['signupUser']),
        gotoLogin() {
            this.$router.push('/login');
        },
        async doSignup() {
            try {
                const { sid } = await this.signupUser({
                    org: this.org,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    userCode: this.userCode,
                    authToken: this.authToken,
                    email: this.email,
                    password: this.password,
                });
                this.$emit('change', sid);
                if (sid) {
                    this.$cookies.set('userEmail', this.email);
                    this.$cookies.set('userOrg', this.org);
                    await this.$router.push('/auth/dashboard');
                } else {
                    this.errorMessage = `Signup failed security checks`;
                    this.showAlert = true;
                }
            } catch (e) {
                console.log('Error Signing Up', e.code, e.message);
                this.errorMessage = ` ${e.message} `;
                this.showAlert = true;
            }
        },
    },
    name: 'App',
};
</script>
